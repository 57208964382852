import {
  Alert,
  Button,
  FormUI,
  Input,
  PlayIcon,
  StopIcon,
  TableSkeleton,
  Tooltip,
  Typography,
  WarningIcon,
  useDesignSystemTheme,
} from '@databricks/design-system';
import { FormattedMessage } from 'react-intl';
import {
  PROMPTLAB_METADATA_COLUMN_LATENCY,
  PROMPTLAB_METADATA_COLUMN_TOTAL_TOKENS,
} from '../../prompt-engineering/PromptEngineering.utils';
import { useMemo } from 'react';
import { type ModelGatewayResponseType } from '../../../sdk/ModelGatewayService';

const { TextArea } = Input;

interface EvaluationCreatePromptRunOutputProps {
  evaluationMetadata: Partial<ModelGatewayResponseType['metadata']>;
  isEvaluating?: boolean;
  isOutputDirty?: boolean;
  evaluationOutput: string;
  evaluationError: string | null;
  evaluateButtonTooltip: string | null;
  onEvaluateClick?: () => void;
  onCancelClick?: () => void;
  disabled?: boolean;
}

/**
 * Part of EvaluationCreatePromptRunModal, houses evaluate/cancel buttons
 * and evaluation output with the metadata
 */
export const EvaluationCreatePromptRunOutput = ({
  evaluationMetadata,
  isEvaluating,
  isOutputDirty,
  evaluationOutput,
  evaluationError,
  evaluateButtonTooltip,
  disabled,
  onEvaluateClick,
  onCancelClick,
}: EvaluationCreatePromptRunOutputProps) => {
  const { theme } = useDesignSystemTheme();

  const metadataOutput = useMemo(() => {
    if (!evaluationMetadata) {
      return null;
    }
    if (isEvaluating) {
      return null;
    }
    return (
      <div css={{ display: 'flex', gap: theme.spacing.xs, alignItems: 'center' }}>
        {PROMPTLAB_METADATA_COLUMN_LATENCY in evaluationMetadata && (
          <Typography.Hint size="sm">
            {Math.round(Number(evaluationMetadata[PROMPTLAB_METADATA_COLUMN_LATENCY]))} ms
            {'MLFLOW_total_tokens' in evaluationMetadata ? ',' : ''}
          </Typography.Hint>
        )}
        {PROMPTLAB_METADATA_COLUMN_TOTAL_TOKENS in evaluationMetadata && (
          <Typography.Hint size="sm">
            <FormattedMessage
              defaultMessage="{totalTokens} total tokens"
              description="Experiment page > artifact compare view > results table > total number of evaluated tokens"
              values={{ totalTokens: evaluationMetadata[PROMPTLAB_METADATA_COLUMN_TOTAL_TOKENS] }}
            />
          </Typography.Hint>
        )}
      </div>
    );
  }, [evaluationMetadata, isEvaluating, theme]);

  return (
    <>
      <div css={{ marginBottom: theme.spacing.md }}>
        <Tooltip title={evaluateButtonTooltip}>
          <Button
            componentId="codegen_mlflow_app_src_experiment-tracking_components_evaluation-artifacts-compare_components_evaluationcreatepromptrunoutput.tsx_85"
            data-testid="button-evaluate"
            icon={<PlayIcon />}
            onClick={onEvaluateClick}
            disabled={disabled}
            loading={isEvaluating}
          >
            <FormattedMessage
              defaultMessage="Evaluate"
              description='Experiment page > new run modal > "evaluate" button label'
            />
          </Button>
        </Tooltip>
        {isEvaluating && (
          <Button
            componentId="codegen_mlflow_app_src_experiment-tracking_components_evaluation-artifacts-compare_components_evaluationcreatepromptrunoutput.tsx_99"
            data-testid="button-cancel"
            icon={<StopIcon />}
            onClick={onCancelClick}
            css={{ marginLeft: theme.spacing.sm }}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              description='Experiment page > new run modal > "cancel" button label'
            />
          </Button>
        )}
      </div>
      <FormUI.Label>
        <FormattedMessage
          defaultMessage="Output"
          description="Experiment page > new run modal > evaluation output field label"
        />
        {isOutputDirty && (
          <Tooltip
            title={
              <FormattedMessage
                defaultMessage="Model, input data or prompt have changed since last evaluation of the output"
                description="Experiment page > new run modal > dirty output (out of sync with new data)"
              />
            }
          >
            <WarningIcon css={{ marginLeft: theme.spacing.xs }} />
          </Tooltip>
        )}
      </FormUI.Label>
      <FormUI.Hint>
        <FormattedMessage
          defaultMessage="This is the output generated by the LLM using the prompt template and input values defined above."
          description="Experiment page > new run modal > evaluation output field hint"
        />
      </FormUI.Hint>
      {!evaluationError && isEvaluating && (
        <div css={{ marginTop: theme.spacing.sm }}>
          <TableSkeleton lines={5} />
        </div>
      )}
      {!isEvaluating && (
        <TextArea rows={5} css={{ cursor: 'default' }} data-testid="prompt-output" value={evaluationOutput} readOnly />
      )}
      {!isEvaluating && evaluationError && <FormUI.Message message={evaluationError} type="error" />}
      <div css={{ marginTop: theme.spacing.sm }}>{metadataOutput}</div>
    </>
  );
};
